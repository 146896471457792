.infoBubble {
  //Position
  position: absolute;
  z-index: 5;
  bottom: 3rem;
  left: 1rem;

  opacity: 1;
  transition: opacity 0.25s;

  .infoMessage {
    //Display
    display: flex;
    flex-direction: column;
    align-items: center;

    //Spacing
    padding: 0.5rem;

    //Sizing
    width: max-content;

    //Text
    color: black;
    font-size: 0.8rem;
    font-weight: bold;

    //Background & Border
    background-color: yellow;
    border: 0.25rem solid hsl(200, 80%, 30%);

    button {
      padding: 0.25rem 0.5rem;
      margin: 0.1rem;
    }
  }
  .arrow {
    margin-left: 1rem;
    width: 0rem;
    border-top: 1rem solid hsl(200, 60%, 30%);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
}
.infoBubble.up {
  top: 3rem;

  .arrow {
    border-top: 0;
    border-bottom: 1rem solid hsl(200, 60%, 30%);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
}
