.resourcePageWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.resourceCategory {
  //Display
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //Spacing
  padding: 0.5rem 0;
  margin: 0.5rem 0;

  //Background & Border
  //background-color: var(--primary-blue);
  border-radius: 1rem;
  width: 100%;

  //Text
  color: aliceblue;

  button {
    width: 15rem;
    height: fit-content;
  }
  .heading{
    background-color: var(--primary-blue);
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    text-align: center;
  }
  a{
    color: darkgreen;
  }
}

.filterColumn{
    //position: sticky;
    height: fit-content;
  //overflow-y: scroll;

}
@media only screen and (min-width: 768px){
  .filterColumn{
    position: sticky;
    top: 0;
    max-height: calc(100vh);
    overflow-y: auto;
  }
}
.resourceError {
  background-color: darkgray;
  color: aliceblue;

  border-radius: 1rem;

  padding: 0.5rem 1rem;
  margin: 1rem;
}
