.bubbleContainer {
  /* Position */
  position: absolute;
  top: -15%;
  right: 10%;

  width: 25vh;
  height: 20vh;

  animation: bubbleFloatRight 3s infinite ease-in;
}
.bubble {
  /* Position */
  position: absolute;
  /* Size & Shape */
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* Color */
  opacity: 0;
  animation: bubbleFloatUp 3s infinite ease-out, fade 3s infinite linear;
}
body.loaded .bubbleContainer {
  animation-play-state: running;
}
body.loaded .bubble {
  animation-play-state: running;
}

.bubble img {
  width: 100%;
  height: auto;
}

.bubbleContainer.one {
  animation-delay: 0;
}
.bubbleContainer.one .bubble {
  animation-delay: 0;
}

.bubbleContainer.two {
  animation-delay: 0.5s;
}
.bubbleContainer.two .bubble {
  animation-delay: 0.5s;
}

.bubbleContainer.three {
  animation-delay: 1s;
}
.bubbleContainer.three .bubble {
  animation-delay: 1s;
}

.bubbleContainer.four {
  animation-delay: 1.5s;
}
.bubbleContainer.four .bubble {
  animation-delay: 1.5s;
}

.bubbleContainer.five {
  animation-delay: 2s;
}
.bubbleContainer.five .bubble {
  animation-delay: 2s;
}

.bubbleContainer.six {
  animation-delay: 2.5s;
}
.bubbleContainer.six .bubble {
  animation-delay: 2.5s;
}

@keyframes bubbleFloatRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-400%) scale(0.3);
  }
}
@keyframes bubbleFloatFlip {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%) scale(0.3);
  }
}

@keyframes bubbleFloatUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
    opacity: 0;
  }
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  90% {
    opacity: 0.8;
  }
}
