.guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.guide .guideSections {
  height: fit-content;
  padding: 1rem;
  background-color: skyblue;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.guide .guideSections .pageTitle {
  margin: 0 0 1rem 0;
}
.guide section h1 {
  text-align: center;
}
.guide section p {
  margin-bottom: 1rem;
  text-align: left;
}
.guide section li {
  margin-bottom: 1rem;
  text-align: left;
  list-style-type: disc;
  list-style-position: inside;
}

.textLink {
  color: aliceblue;
  font-weight: bold;
  background-color: var(--primary-green);
  padding: 0.25rem;
  border-radius: 1rem;
}

.sectionButton {
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  width: 15rem;
}

@media only screen and (min-width: 768px) {
  .guideSections {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: auto;
  }
}

