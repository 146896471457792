.discWrap {
  display: flex;
  //flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 100%;
}
.discWrap.fullImg {
  //flex-direction: row;
  height: fit-content;
  //width: fit-content;
}

/* Discussion Image(s) Wrap */
.discImgFrame {
  margin: 1rem;
  padding: 1rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsl(120, 60%, 70%);
  border-radius: 1rem;
}

/* Image Grid */
.discGrid {
  height: fit-content;
  width: 100%;

  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
}
.discGridCharacter {
  height: fit-content;
  min-width: 7rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: grow 1.5s infinite ease-in-out;

  background-color: var(--light-blue);
  border-radius: 100%;
  box-shadow: 0 0 2rem aliceblue;
}
.discGridCharacter p {
  background-color: aliceblue;
  border-radius: 1rem;
  padding: 0.1rem 1rem;
}
.discGridCharacter.zoom{
  width: 15rem;
  height: 15rem;
}
.discGridImg:hover {
  z-index: 5;
  animation: growBig 0.25s ease-out forwards;
}
@keyframes grow {
  50% {
    transform: scale(0.95);
  }
}
@keyframes growBig {
  0%{
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.discGridCharacter {
  width: 10rem;
  height: 10rem;
}

#discGridImg {
  width: 80%;
  height: auto;
}

/* Full Images */
.discFullImgFrame {
  position: static;
  width: 50vw;
  height: fit-content;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
.discFullImg {
  width: 100%;
  max-width: 50vw;
  height: auto;
  border-radius: 1rem;
}

/* Text */
.discText {
  max-width: 100%;

  margin: 1rem;
  padding: 1rem;

  //background-color: var(--dark-accent);
  border-radius: 1rem;
}
.discText p {
  background-color: var(--light-blue);
  border-radius: 1rem;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  color: black;
  font-weight: bold;
}
.discWrap.fullImg .discText {
  //width: 35%;
  max-height: 80vh;
  overflow-y: auto;
}

.discussSpeechBubble {
  border-radius: 1rem;
  padding: 0.25rem;
  color: hsl(200,50%,40%);
  font-weight: bold;
}
.textline {
  border: 0.25rem solid red;
}
