.videoWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.video {
  width: 80vw;
  height: 80vh;
  background-color: var(--primary-green);
  border-radius: 1rem;
  border: 0.5rem solid aliceblue;
}
