code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* GLOBAL */
html,
body,
#root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 95vh;
  width: 100%;
  font-family: "Quicksand", serif;
  box-sizing: border-box;
  background-color: aliceblue;
}
#root {
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
