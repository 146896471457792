/* Page Container */
.sessionMap {
  min-height: 100vh;
  width: 100%;
  padding: 0;

  display: grid;
  grid-template-rows: 1fr auto;


    .mapRowGrid{
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr auto;
    }
  .trainCol {
      $trainHeight: max(20rem, 40vh);

    position: relative;
      height: 100%;


width: 100%;
    max-width: max( 59rem, 60vh);

    overflow: hidden;
  }
  .trainWrap {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;

    height: 50%;
    width: 100%;
    //width: fit-content;
    //width: -moz-fit-content;

    padding: 0;
    margin: 0;

      z-index: 1;

    //border: 0.25rem solid green;

    .sizeTrain {
      //Position
      position: relative;
      bottom: 0;
      right: 0;

      //Sizing
      height: 100%;
      width: auto;

      opacity: 0;
      //border: 0.25rem solid orange;
    }

    .splashTrain {
      //--trainHeight: max(20rem, 50vh);

      //Position
      position: absolute;
      right: 0;
      left: auto;
      bottom: 5%;

      //Sizing
      height: var(--trainHeight);
        min-height: 20rem;
      width: calc(var(--trainHeight) * 3.12);
        min-width: 60rem;

      //Spacing
      margin: 0;

      .trainWheel {
        animation-play-state: paused;
      }
    }
  }
}

#bg {
  position: relative;
  bottom: 0;
  min-height: max(75vh,30rem);
  //width: 95%;
  background-image: url("../splash/splashBGscrollCrop.jpg");
  background-size: auto 100%;

  background-position: 0% 0;

  transition: background-position 3s;

    display: grid;
    grid-template-columns: 1fr auto;
}

@media only screen and (max-width: 767px) {
  #bg {
    background-image: none;
  }
}
