.icon.rightArrow {
  width: 0;
  height: 0;
  margin: 0 0 0 0.4rem;

  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-left: 1em solid white;
}

.icon.downArrow {
  width: 0;
  height: 0;
  margin: 0.1rem 0 0 0.4rem;

  border-top: 1em solid white;
  border-bottom: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
}
.icon.upArrow {
  width: 0;
  height: 0;
  margin: -0.5rem 0 0.2rem 0.4rem;

  border-top: 0.5em solid transparent;
  border-bottom: 1em solid white;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
}

.icon.leftArrow {
  width: 0;
  height: 0;
  margin: 0 0.4rem 0;

  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-right: 1em solid white;
}
.icon.zoom {
  width: 1.5rem;
  height: 1.5rem;
}
.icon.info {
  width: 2rem;
  height: 2rem;
}
.icon.pointer {
  width: 80%;
  height: 80%;
}
