/* Containers */
.ReaderControlBar {
  box-sizing: border-box;
  top: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 95vw;

  transition: opacity 0.5s;

  .standard-close {
    padding: 0;

    .closeButton {
      position: relative;

      p {
        line-height: 1rem;
        margin-bottom: 0;
      }
    }
  }
}
#controlBar {
  //opacity: 0;
}
#controlBar:hover {
  opacity: 1;
}

.mobileTop {
  display: flex;
  align-items: center;
  padding: 0;

  .mobile-close {
    display: none;
  }
}
.transNarrCont {
  width: 100%;
  display: flex;
  align-items: center;
}

/* READER PROGRESS BAR */
.readerProgressWrap {
  //width: 100%;
  flex: 1;
}
.ReaderProgress {
  /* Display */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* Size */
  width: 100%;
  height: fit-content;
  margin: 0.5rem 0.5rem 0 0;
  background-color: transparent;
}
.pageCounter {
  display: inline-flex;
  width: fit-content;
  padding: 0.5rem;
  margin: 0.5rem;
  padding-right: 1rem;
  background-color: var(--light-blue);
}
#pageCounterWrap {
  //background-color: var(--light-blue);
}
#pageInput {
  width: 3rem;
  background-color: aliceblue;
  border-radius: 1rem;
  padding: 0;

  text-align: center;
}
#pageInput.error {
  background-color: hsl(0, 70, 70);
}
#totalPages {
  width: fit-content;
}
label {
  width: 5rem;
}
.progressSliderWrap {
  width: 90%;
  margin: 0.5rem;
  height: fit-content;
  background-color: lightblue;
}
#progressSliderWrap {
  //background-color: var(--light-blue);
}
#progressSlider {
  width: 90%;
  height: 1em;

  border: 5px solid white;
  border-radius: 1rem;
  //background-color: white;
}
.rightButtons {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Reader Start */

#readerPhoto {
  margin: 0.5rem 0 0.5rem 0;
  width: 10rem;
  height: auto;
  border-radius: 100%;
  border: 0.2em solid hsl(200, 70%, 70%);
  background: hsl(128, 35%, 45%);
  background: linear-gradient(
    180deg,
    hsl(128, 45%, 60%) 0%,
    hsl(128, 25%, 45%) 100%
  );
  box-shadow: none;
}
@keyframes pulse {
  50% {
    transform: scale(0.9);
  }
}

/* Page Arrows */
.pageArrows {
  max-width: 100%;
  margin-bottom: 2rem;

  .col {
    display: flex;
    justify-content: center;
  }
}
#nextPage:hover {
}
/* Next Page */
#nextPage {
  position: relative;
  height: 4rem;
  width: 4rem;
  padding: 0;
  border-radius: 100%;
}
.nextPageSymbol {
  width: 0;
  height: 0;
  margin: 0 0 0 0.4rem;

  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  border-left: 2em solid white;
}
/* Prev Page */
#prevPage {
  z-index: 5;
  height: 4rem;
  width: 4rem;
  padding: 0;
  border-radius: 100%;
  //left: 0;
  //top: 50%;
}
.prevPageSymbol {
  width: 0;
  height: 0;
  margin: 0 0.4rem 0 0;

  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  border-right: 2em solid white;
}
#prevPage.disabled {
  background: hsl(200, 0%, 80%);
  background: linear-gradient(
    180deg,
    hsl(200, 0%, 80%) 0%,
    hsl(200, 0%, 50%) 100%
  );
  border-color: hsl(200, 0%, 70%);
  box-shadow: none;
}
#prevPage.disabled .prevPageSymbol {
  border-right-color: dimgray;
}
.mobileArrows {
  display: none;
}

/* Misc Buttons */
.readTranslate {
  position: static;
  padding: 0.5rem;
}
.close.reader {
  position: static;
  margin: 0.5rem;
}
#standardClose {
  position: relative;
}
#mobileClose {
  display: none;
}
.showControls {
  position: relative;
  top: 2rem;
}

/* ------------------*/
/* NARRATION CONTROL */

/*  Container  */
.NarrationControl {
  position: relative;
  /* Display */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Size */
  height: 100%;
  padding: 0.25rem;
  margin: 0.5rem;
}

/* Button Container */
.narrControls {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CONTROL BUTTONS */

/* Active Buttons */
.playButton,
.pauseButton,
.replayButton,
.loadingButton {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;
  box-shadow: 0 0 0.5rem;
  border-radius: 1.5rem;
}
.playButton {
  padding: 0.4rem 0.4rem 0.4rem 0.6rem;
}
.pauseButton {
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  background-color: var(--yellow);
}
.replayButton {
  padding: 0.5rem;
  background-color: var(--red);
}
.loadingButton {
  height: 4rem;
  width: 4rem;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
}
/* Disabled Buttons */
.playButton.disabled,
.pauseButton.disabled,
.replayButton.disabled {
  background: hsl(200, 0%, 80%);
  border-color: hsl(200, 0%, 70%);
  box-shadow: none;
}

/* CONTROL BUTTON SYMBOLS */

/* Play Button Symbol */
/* Active */
.playSymbol {
  width: 0;
  height: 0;
  margin: 0;

  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-left: 1em solid white;
}
/* Disabled */
.playButton.disabled .playSymbol {
  border-left-color: dimgray;
}

/* Pause Button Symbol */
/* Active */
.pauseSymbol {
  background-color: white;
  width: 0.3em;
  height: 1em;

  margin: 0.1rem;
}
/* Disabled */
.pauseButton.disabled .pauseSymbol {
  background-color: dimgray;
}

/* Replay Symbol */
/* Active */
.stopSymbol {
  background-color: white;
  width: 1rem;
  height: 1rem;
}
.replaySymbolBar {
  background-color: white;
  width: 0.3em;
  height: 1em;
  margin: 0rem;
}
.replaySymbolTriangle {
  width: 0;
  height: 0;
  margin: 0;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-right: 1em solid white;
}
/* Disabled */
.replayButton.disabled .replaySymbolBar {
  background-color: dimgray;
}
.replayButton.disabled .replaySymbolTriangle {
  border-right-color: dimgray;
}
