/* Page Container */
#Library {
  position: absolute;
}

#Library.scrollLock {
  overflow: hidden;
  height: 100%;
}

.Library {
  //Display
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;

  //Sizing
  width: 100%;

  //Background
  //background-image: url('./LibraryFull.png');
  background-position: bottom;
  background-repeat: repeat-y;
  //background-attachment: fixed;
  background-size: cover;
  padding-bottom: 0rem;

  //Scrollbar
  scrollbar-color: var(--green) var(--light-blue);
}




@media only screen and (max-width: 767px) {
  #Library {
    height: 100%;
    overflow-y: scroll;

    .sallyBubbleWrap {
      display: none;
    }
    .libraryBottom {
      display: none;
    }
    .libraryList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .libraryBody {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .libraryScroll {
    height: 80vh;
    margin-bottom: 1rem;
  }

  .libraryBody {
    display: flex;
    flex-direction: column-reverse;
  }
  .libraryInfo {
    height: 100%;
  }
  .summaryEntryTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
