.infoBubble {
  position: absolute;
  z-index: 5;
  bottom: 3rem;
  left: 1rem;
  opacity: 1;
  transition: opacity 0.25s;
}
.infoBubble .infoMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  width: max-content;
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: yellow;
  border: 0.25rem solid #0f618a;
}
.infoBubble .infoMessage button {
  padding: 0.25rem 0.5rem;
  margin: 0.1rem;
}
.infoBubble .arrow {
  margin-left: 1rem;
  width: 0rem;
  border-top: 1rem solid #1f5c7a;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}

.infoBubble.up {
  top: 3rem;
}
.infoBubble.up .arrow {
  border-top: 0;
  border-bottom: 1rem solid #1f5c7a;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}

