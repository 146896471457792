.dramaWrap {
  overflow-anchor: none;
}
.dramaWrap ul {
  padding-left: 1rem;
}
.dramaWrap ul li {
  margin-bottom: 1rem;
  list-style-type: disc;
}
.dramaWrap ul ul li {
  list-style-type: circle;
}
.dramaWrap ul ul ul li {
  list-style-type: disc;
}
.dramaWrap h1 {
  margin: 1rem;
}
.dramaWrap section {
  overflow-anchor: none;
  background-color: var(--dark-accent);
  border-radius: 1rem;
  padding: 1rem;
  color: white;
  height: fit-content;
  width: fit-content;
  max-width: 100%;
}
.dramaWrap .dramaSupplies {
  max-width: 20rem;
}
.dramaWrap .dramaSupplies section {
  position: sticky;
  top: 1rem;
}
.dramaWrap .dramaSupplies ul li {
  margin-bottom: 1rem;
}
.dramaWrap .dramaBody {
  overflow-anchor: none;
}
.dramaWrap .dramaBody section {
  margin-bottom: 1rem;
}
.dramaWrap .dramaBody h2 {
  text-align: center;
  font-weight: bold;
}
.dramaWrap .dramaBody iframe {
  width: 100%;
  height: 500px;
  border-radius: 1rem;
}
.dramaWrap .dramaBody img {
  width: 100%;
  border-radius: 1rem;
}
.dramaWrap .dramaBody .dramaVideos {
  border-top: 0.5rem solid var(--primary-blue);
  padding-top: 1rem;
  margin-top: 1rem;
}
.dramaWrap .dramaInstructions {
  width: 100%;
  overflow-anchor: none;
}
.dramaWrap .dramaInstructions a {
  background-color: var(--primary-green);
  padding: 0.25rem;
  border-radius: 1rem;
}
.dramaWrap .dramaVideoTranscript {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dramaWrap .dramaVideoTranscript h3 {
  font-weight: bold;
}
.dramaWrap .dramaVideoTranscript p {
  margin-bottom: 1rem;
  width: 100%;
}
.dramaWrap aside img {
  width: 100%;
}
