#Summary {
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: rgba(105, 105, 105, 0.75);
  border-radius: 0;
}
#Summary .sessionHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-blue);
  border: 0;
  box-shadow: 0 0 1rem midnightblue;
  padding: 0.5rem;
}
#Summary .sessionHeader h2 {
  width: fit-content;
}
#Summary .closeButton {
  height: fit-content;
}
#Summary .closeButton p {
  margin-bottom: 0;
}

#Summary.noSummary {
  border: none;
}

.materialList {
  margin: 1rem;
}

