/* ELEMENTS */
.pageTitle{
    /* Size */
    width: fit-content;
    padding: 1rem;
    margin: 1rem 0 2rem 0;
    /* Border & Background */
    border-radius: 1rem;
    background: var(--dark-accent);
    /*box-shadow: 0 0 1rem midnightblue;*/
    /* Text */
    text-align: center;
    color: aliceblue;
}
.sectionHeader{
    background-color: var(--dark-accent);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    color: aliceblue;
    width: 100%;
    text-align: center;
}
.pageBody{
    background-color: rgba(135, 206, 235, 1);
    border-radius: 1rem;
    height: fit-content;
    margin-bottom: 1rem;
}
/* BUTTONS */
button {
  padding: 0.65rem;
  margin: 0.4rem;
  height: fit-content;

  background-color: var(--primary-green);

  border: 0.2rem solid white;
  border-radius: 1rem;

  font-weight: bold;
  font-size: 1em;
  color: white;
}

button:hover {
  transition: all 0.1s;
  border-color: yellow;
}
button:active {
  animation-name: click;
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}
@keyframes click {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(0.9);
  }
}
Link,
a {
  text-decoration: none;
  color: white;
}

/* SCROLL BARS */

.App {
  scrollbar-color: var(--green) var(--light-blue);
}

.App -webkit-scrollbar-track {
  background-color: var(--light-blue);
}

.App -webkit-scrollbar-thumb {
  background-color: var(--green);
}

/* Control Element Global Styles */
.controlBox {
  /* Background & Border */
  /* background: hsla(200,50%,60%,1); */
}
.controlBox .label {
  padding: 0.5rem;
  margin: 0.5rem;
  /* Background & Border */

  border-radius: 1rem;
  /* Text */
  text-align: center;
  color: aliceblue;
}
.controlBox .label hr {
  border-bottom: 0.1rem solid aliceblue;
  width: 50%;
  margin: 0.25rem auto;
}

/* CLOSE BARS */
.closeBar {
  width: 100%;
  height: fit-content;
  display: inline-flex;
  justify-content: flex-end;
}
.close {
  margin: 0;
  position: absolute;
  right: 1rem;
  background-color: hsl(0, 50%, 70%);
}

/* SPEECH BUBBLES */
.speechBubble {
  background-color: white;
  color: black;
  padding: 1rem;
  border-radius: 1rem;
  border: 0.25rem solid aliceblue;
  line-height: 2.3rem;
  height: fit-content;
}
