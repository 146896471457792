/* Media Queries */
@media only screen and (max-width: 1025px) {
  .ReaderStart {
    max-width: 30rem;
  }
  .readerReadBy {
    display: flex;
    align-items: center;
  }
  #readerPhoto {
    /*width: 10rem;*/
  }
}

@media only screen and (max-width: 600px) {
  /*
    .ReaderContent{
        align-items: flex-end;
    }

     */
  .ReaderPages {
    width: 95%;
  }
  .pageImgFrame.tall {
    width: 80%;
    height: fit-content;
  }
  .pageImgFrame.tall img {
    width: 100%;
    height: auto;
  }
  .pageImgFrame.wide {
    width: 100%;
    height: fit-content;
  }
  .pageImgFrame.wide img {
    width: 100%;
    height: auto;
  }
  .ReaderProgress {
    display: flex;
    flex-direction: column;
  }
  .ReaderStart {
    max-width: 100%;
  }
  .readerReadBy {
    flex-direction: column;
    align-items: center;
  }
  #readerPhoto {
    width: 100%;
    height: auto;
  }
  .discWrap.fullImg {
    //flex-direction: column;
    //height: 100%;
  }
  .discFullImgFrame {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .readerProgressWrap {
    display: none;
  }
  .ReaderContent,
  .ReaderPages {
    flex-direction: column;
    align-items: center;
  }
  .ReaderContent #nextPage.default,
  .ReaderContent #prevPage.default {
    /* display: none; */
  }
  .mobileArrows {
    display: flex;
  }
  .ReaderContent.tallContent .readerPageAndTranslation {
    flex-direction: column;
  }
}
@media only screen and (max-width: 350px) {
  .mobileTop {
    flex-direction: column-reverse;
    align-items: center;
  }
  #mobileClose {
    position: static;
  }
}

@media only screen and (max-height: 500px) {
  .ReaderControlBar {
    flex-direction: row;
    width: fit-content;
  }
  .ReaderProgress {
    display: none;
  }
  .pageImgFrame {
    height: 65vh;
  }
  #nextPage {
    /*position: fixed;
        bottom: 0;

         */
  }
  /* Prev Page */
  #prevPage {
    /*
        position: fixed;
        bottom: 0;

         */
  }
}
