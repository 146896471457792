.libraryDiscussion {
  //Position
  position: relative;

  /* Display */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Size */
  width: 13rem;
  height: 15rem;

  //Spacing
  margin: 1em;

  /* Border & Background */
  border-radius: 1em;
  border: 0.25rem solid aliceblue;

  //background: transparent;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;

    background-color: var(--dark-accent);

  box-shadow: 0 0 1rem midnightblue;

    /* Text Box */
    #summaryMediaDets {
        //Position
        position: absolute;
        top: -1rem;

        //Display
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /* Size */
        width: 100%;
        height: fit-content;
        padding: 0.25rem;

        /* Background */
        background-color: var(--primary-green);
        border-radius: 1rem;
        border: 0.25rem solid aliceblue;

        /* Animation */
        animation: none;
    }
}

.mediaListEntry:hover {
  animation: none;
}


