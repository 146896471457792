.lightbox {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox img {
  border: 0.5rem solid aliceblue;
  border-radius: 1rem;
  background-color: aliceblue;
}

.lightbox.skinnyWindow img {
  width: 80vw;
  height: auto;
}

.lightbox.wideWindow img {
  height: 80vh;
  width: auto;
}

