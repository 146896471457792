/* APP GLOBAL */

@import "utilityStyles";
* {
    overflow-anchor: none;
}
.App {
  //Display
  display: grid;
  grid-template-rows: auto 1fr;
  //flex-direction: column;
  //align-items: center;
  //justify-content: flex-start;

  //Sizing
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  font-weight: 400;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;

  background-color: var(--primary-blue);
}

@media only screen and (max-height: 500px) {
  /*
  .App{
    height: 60%;
    width: 80%;
    margin: 0;
  }

   */
}
