#sessionWrap{
    z-index: 2;
    transition: opacity 0.25s;
    //min-height: 15rem;
    height: 100%;
    width: 20rem;
}
.sessionItem {
    background-color: transparent;
    //box-shadow: 0 0 1rem dimgrey;
    border: 0;
    padding: 1rem;

    height: 100%;
    margin: 0;

    //display: grid;
    //grid-template-rows: auto 1fr auto;

    button {
        border-radius: 100%;
        border: 0.2rem solid aliceblue;
        width: 3rem;
        height: 3rem;
        padding: 0;
    }
    button.disabled {
        background-color: lightgray;
        color: dimgrey;
    }
}
.sessionTitle {
    margin: 0.25rem;
    background-color: var(--primary-green);
    border-radius: 1rem;
    border: 0.25rem solid aliceblue;
    padding: 0.5rem 1rem;

    ul{
        list-style-type: circle;
    }
}
.sessionGraphic {
  //Position
  position: relative;

  /* Display */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  //Sizing
    //max-height: 12rem;
    height: fit-content;
    width: 15rem;

  //Spacing
  margin: auto;
  padding: 0;

  /* Border & Background */
  border-radius: 1em;
  //border: 0.25rem solid aliceblue;
  //background: var(--dark-accent);
  //border: 0;

  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  //background-color: aliceblue;

  overflow-y: hidden;

  img {
    width: 100%;
      height: auto;
    background-color: var(--dark-accent);
      border: 0.25rem solid aliceblue;
    border-radius: 1rem;
  }

    .experiment{
        background-color: var(--dark-accent);
        width: 100%;
        padding: 1rem;

        svg{
            //width: 10rem;
            height: 12rem;
        }
    }

  //box-shadow: 0 0 1rem var(--light-blue);
}
.materialTitle{
    width: fit-content;
    max-width: 12rem;
    background-color: var(--primary-green);
    border-radius: 1rem;
    border: 0.25rem solid aliceblue;
    margin: 0.25rem auto 0 auto;
    padding: 0.5rem 1rem;
}

.changeSession {
  height: 3.5rem;
  width: 3.5rem;
  position: absolute;
  padding: 0.25rem;
  border-radius: 100%;
}
.changeSession.forward {
  right: 0;
}
.changeSession.back {
  left: 0;
}
