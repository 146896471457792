button {
  width: fit-content;
}
button span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.zoomButton {
  border-radius: 100%;
}

.zoomWrap {
  position: absolute;
  top: 1rem;
  right: -4rem;
}
