.Splash {
  position: relative;
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
}
.Splash .TopNav {
  position: relative;
  width: 100%;
}
.Splash .splashBody {
  height: 100%;
  overflow-y: hidden;
  bottom: 0;
}
.Splash .splashBody .menuWrap {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem;
  justify-content: center;
}
.Splash .splashGraphics {
  position: absolute;
  height: 100%;
  bottom: 0;
}
.Splash .splashGraphics .trainCol {
  position: relative;
  max-width: 124vh;
  overflow: hidden;
}
.Splash .splashGraphics .trainCol .trainWrap {
  right: 0;
  left: auto;
  bottom: 0;
  height: 100%;
  width: 100vh;
  padding: 0;
  margin: 0;
}
.Splash .splashGraphics .trainCol .splashTrain {
  --trainHeight: 40vh;
  position: absolute;
  right: 0;
  left: auto;
  bottom: 5%;
  height: var(--trainHeight);
  width: calc(var(--trainHeight) * 3.1238);
  margin: 0;
}
.Splash .menu {
  background-color: var(--primary-blue);
  border-radius: 1rem;
  padding: 1rem;
  position: absolute;
  z-index: 3;
}
.Splash .menu button {
  width: 15rem;
}
.Splash .menu img {
  background-color: white;
  border-radius: 3rem;
  padding: 1rem;
  margin-bottom: 1rem;
}
.Splash .menu .info {
  border: 0.15rem solid aliceblue;
  border-radius: 100%;
  margin: 0 0 0 0.5rem;
}
.Splash .menu .langs {
  background-color: aliceblue;
  border-radius: 1rem;
}
.Splash .menu .langs button {
  width: fit-content;
}
.Splash .menu .langs .current {
  background-color: var(--primary-blue);
}

.splashNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* Sizing */
  margin-top: 1rem;
  height: 100%;
}
.splashNav nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.splashNav .splashLogo {
  /* Size */
  width: 100%;
  max-width: 12rem;
  height: fit-content;
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  /* Background & Border */
  border-radius: 2rem;
  background-color: white;
}
.splashNav .splashLogo img {
  width: 100%;
  height: auto;
}

@media only screen and (max-height: 500px) {
  .Splash .menuWrap {
    justify-content: flex-end !important;
  }
}

