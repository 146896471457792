.Splash {
  position: relative;
  width: 100vw;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr;
  align-items: center;

  //overflow-y: hidden;

  //background-color: var(--dark-accent);

  .TopNav {
    position: relative;
    //top: 0;
    width: 100%;
  }

  .splashBody {
    height: 100%;
    overflow-y: hidden;
    bottom: 0;

    .menuWrap {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 1rem;
      justify-content: center;
    }
  }

  .splashGraphics {
    position: absolute;
    height: 100%;
    bottom: 0;
    //border: 1rem solid aliceblue;
    //border-radius: 1rem;

    .trainCol {
      position: relative;
      max-width: 124vh;
      overflow: hidden;

      .trainWrap {
        //position: absolute;
        right: 0;
        left: auto;
        bottom: 0;

        height: 100%;
        width: 100vh;

        padding: 0;
        margin: 0;
      }

      .splashTrain {
        --trainHeight: 40vh;

        //Position
        position: absolute;
        right: 0;
        left: auto;
        bottom: 5%;

        //Sizing
        height: var(--trainHeight);
        width: calc(var(--trainHeight) * 3.1238);

        //Spacing
        margin: 0;
      }
    }
  }

  .menu {
    background-color: var(--primary-blue);
    //border: 0.5rem solid var(--primary-blue);
    border-radius: 1rem;
    //box-shadow: 0 0 1rem midnightblue;
    padding: 1rem;
    position: absolute;
    z-index: 3;
    //margin: 1rem;
    //top: 2rem;
    //right: 2rem;
    //margin-right: 2rem;

    button {
      width: 15rem;
    }

    img {
      background-color: white;
      border-radius: 3rem;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    .info {
      border: 0.15rem solid aliceblue;
      border-radius: 100%;
      margin: 0 0 0 0.5rem;
    }

    .langs{
      background-color: aliceblue;
      border-radius: 1rem;
      button{
        width: fit-content;
      }
      .current{
        background-color: var(--primary-blue);
      }
    }
  }
}

//SplashNav
.splashNav {
  //Display
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* Sizing */
  margin-top: 1rem;
  height: 100%;

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .splashLogo {
    /* Size */
    width: 100%;
    max-width: 12rem;
    height: fit-content;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;

    /* Background & Border */
    border-radius: 2rem;
    background-color: hsl(0, 0%, 100%);

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-height: 500px) {
  .Splash {
    .menuWrap {
      justify-content: flex-end !important;
    }
  }
}
