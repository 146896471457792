table {
  border: 0.5rem solid aliceblue;
  font-weight: bold;
    background-color: var(--light-blue);
    color: black;
    margin: auto;
}
thead {
  background-color: var(--blue);
  padding: 1rem;
  border-bottom: 0.25rem solid aliceblue;
    color: aliceblue;
}
tr {
  border-bottom: 0.25rem solid aliceblue;
}

td,
th {
  padding: 1rem;
  border-right: 0.25rem solid aliceblue;
}
