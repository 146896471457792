.tableContents {
  //opacity: 0.5;
  //Position
  position: relative;
  //margin: 0rem;
  //bottom: 0;
  //left: 0;
  z-index: 5;

  width: fit-content;

  border-top: 0.5rem solid var(--primary-blue);
  color: aliceblue;
  font-weight: bold;

  //Spacing
  padding: 0.5rem;
  margin: auto;

  //Background & Border
  background-color: var(--dark-accent);

  button {
    margin: 0.25rem;
    width: 3rem;

    padding: 0.25rem;
    border-radius: 1rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg{
          width: 2rem;
          height: 2rem;
      }
    //box-shadow: 0 0 1rem midnightblue;
    //background-color: var(--dark-accent);
    //color: aliceblue;
  }
  button.current {
    background-color: var(--primary-blue);
  }

  .materialWrap {
    background-color: var(--light-green);
    border-radius: 1rem;
    padding: 0.2rem;
    margin: 0.5rem 0;

      display: flex;
    //box-shadow: 0 0 1rem midnightblue;
    //border: 0.2rem solid aliceblue;

    button {
      box-shadow: none;
    }
  }
}
