.resourcePageWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.resourceCategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  border-radius: 1rem;
  width: 100%;
  color: aliceblue;
}
.resourceCategory button {
  width: 15rem;
  height: fit-content;
}
.resourceCategory .heading {
  background-color: var(--primary-blue);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  text-align: center;
}
.resourceCategory a {
  color: darkgreen;
}

.filterColumn {
  height: fit-content;
}

@media only screen and (min-width: 768px) {
  .filterColumn {
    position: sticky;
    top: 0;
    max-height: calc(100vh);
    overflow-y: auto;
  }
}
.resourceError {
  background-color: darkgray;
  color: aliceblue;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin: 1rem;
}

