// View Wrap
.Read {
  //Sizing

  min-height: 100%;
  max-width: 100%;


}
.ReaderContent {
  max-width: 95vw;
  display: grid;
  grid-template-columns: auto 1fr auto;
  //margin-right: 0;
}
.materialHeader {
  padding: 0.25rem 1rem;
  margin: 1rem 0;

  background-color: var(--dark-accent);
  border-radius: 1rem;

  //font-weight: normal;

  color: aliceblue;

  h1 {
    font-weight: normal;
  }
}

/* Page Image & Container */
.pageImgFrame {
  //Sizing
  box-sizing: border-box;
  height: 80vh;
  width: fit-content;

  //Display
  display: inline-flex;
  justify-content: center;
  align-items: baseline;

  .pageImg {
    // Sizing
    box-sizing: border-box;
    width: auto;
    height: 100%;

    // Border & Shadow
    border: 0.5rem solid aliceblue;
    border-radius: 1rem;
    box-shadow: 0 1rem 1rem hsla(0, 0%, 0%, 0.3);

    //Loading Style
    min-width: 20vw;
  }
}
.pageImgFrame.skinnyWindow {
  width: 80vw;
  height: auto;

  .pageImg {
    width: 100%;
    height: auto;
  }
}
.pageImgFrame.zoom {
  //Sizing
  width: 80vw;
  height: 80vh;
  box-sizing: border-box;
  padding: 1rem 0.5rem;

  //Display
  display: flex;
  align-items: baseline;
  overflow-y: auto;

  //Background & Border
  border: 0.5rem solid aliceblue;
  border-radius: 1rem;

  .pageImg {
    //Sizing
    width: 100%;
    height: auto;

    //Display
    overflow-y: auto;

    //Border
    border: none;
  }
}

/* Translation */
.translation {
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Size */
  padding: 0.5rem;
  background-color: var(--dark-accent);
  color: aliceblue;
  border-radius: 1rem;

  p {
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.2rem;
    text-align: center;
  }
}

#nextPageInfo{
  right: 1rem;
  left: auto;

  .arrow{
    margin-right: 1rem;
    background-color: var(--light-blue);
  }
}

.readContentWrap, .readContentWrap div{
}

#imgWrap{
  height: 100%;
  width: fit-content;
}
#imgWrap.sizeWidth{
  width: 100%;
  height: fit-content;
}
#pageImg{
  border: 0.25rem solid aliceblue;
  border-radius: 1rem;
  height: 100%;
  width: auto;
}
#imgWrap.sizeWidth #pageImg{
  width: 100%;
  max-height: 100%;
}

.zoomWrap{
  position: absolute;
  top: 0;
  right: 0;
}

