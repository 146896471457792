.ReaderStart {
  //Position
  position: relative;

  //Display
  //display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;

  // Sizing
  height: fit-content;
  max-width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  //Background & Border
  border-radius: 1rem;
  background-color: var(--dark-accent);
  /*
    background: linear-gradient(
                    180deg,
                    hsl(200,70%,60%) 0%,
                    hsl(200,70%,40%) 100%
    );

     */

  //Text
  text-align: center;

  .readerTitleAuthor {
    margin-bottom: 0.5rem;
  }

  .readByName {
    margin: 0.5rem;
  }

  .readerTitleAuthor,
  .readerReadBy {
    background-color: hsla(0, 0%, 100%, 0.2);
    padding: 0.5rem;
    border-radius: 1rem;
  }

  hr {
    border-color: hsla(0, 0%, 0%, 0.2);
    width: 60%;
  }

  #playNarration {
    margin-top: 1rem;
  }

  #readerStartLoading {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--yellow);
    color: var(--blue);

    p {
      animation: pulse 2s infinite ease-in-out;
      margin-right: 1rem;
    }
  }

  .readerStartLoadingWrap {
    padding-right: 1.5rem;
    position: relative;
  }

  .startDescr {
    border-top: 0.25rem solid var(--primary-blue);
    border-bottom: 0.25rem solid var(--primary-blue);

    margin: 1rem 0;
    padding: 0.5rem 0;
  }

  .sessionInfo {
    background-color: var(--primary-blue);
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    border: 0.25rem solid aliceblue;

    h1 {
      margin: 0;
    }
  }
}
