.splashGraphics {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  margin: 0;

  transform: translate3d(0, 0, 0);
  background-image: url("./splashBGscrollCrop.jpg");
  background-repeat: repeat-x;
  background-size: auto 100%;

  background-position: 83.25% 0%;

  /* transform: translate3d(-95%, 0, 0); */

  animation: moveBG 35s infinite linear;
}

@keyframes moveBG {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 83.25% 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .splashTrain {
    right: 0;
    left: auto;
  }
}
