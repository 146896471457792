.preloader {
  position: fixed;
  width: 1px;
  height: 1px;
  opacity: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
