/* Full Screen BG Container */
#complete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 6;
}

/* Congrats Window */
.completeWindow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  background: var(--dark-accent);
  box-shadow: 0 0 1rem midnightblue;
}
.completeWindow .label {
  padding: 0.5rem;
}
/* Option Buttons Container */
.congratsOptions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.congratsOptionsContainer {
  padding: 0.5rem;
}

.otherOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.sallyWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 100%;
}

.sallyWrap .sally {
  --sallyWidth: 5rem;
  position: relative;
  left: 4rem;
  bottom: auto;
}
.completeWindow #sallyBody {
  left: 0;
}
.sallyWrap .sally .speechBubble {
  left: -150%;
  top: 0;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  .completeWindow {
    width: 80%;
  }
  h1 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-height: 600px) and (orientation: landscape) {
  .completeWindow {
    justify-content: space-between;
  }
}
