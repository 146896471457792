.TopNav {
  position: sticky;
  top: 0;
  z-index: 15;
  align-items: center;
  height: fit-content;
  background-color: aliceblue;
  width: 100%;
}
.TopNav button {
  width: 11rem;
}
.TopNav button span .icon {
  margin: 0 0.25rem;
}
.TopNav .Button.current {
  background-color: var(--primary-blue);
}
.TopNav .icon {
  max-width: 5rem;
  height: fit-content;
  margin: 0.5rem;
  align-items: center;
}
.TopNav .icon img {
  width: 100%;
  height: auto;
}

.TopNav.mobile .navButtons {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: aliceblue;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.navButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.langButtons {
  position: absolute;
  left: 0;
  z-index: 10;
  background-color: aliceblue;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.langDrop button {
  width: 7rem;
}

