#sessionWrap {
  z-index: 2;
  transition: opacity 0.25s;
  height: 100%;
  width: 20rem;
}

.sessionItem {
  background-color: transparent;
  border: 0;
  padding: 1rem;
  height: 100%;
  margin: 0;
}
.sessionItem button {
  border-radius: 100%;
  border: 0.2rem solid aliceblue;
  width: 3rem;
  height: 3rem;
  padding: 0;
}
.sessionItem button.disabled {
  background-color: lightgray;
  color: dimgrey;
}

.sessionTitle {
  margin: 0.25rem;
  background-color: var(--primary-green);
  border-radius: 1rem;
  border: 0.25rem solid aliceblue;
  padding: 0.5rem 1rem;
}
.sessionTitle ul {
  list-style-type: circle;
}
.sessionGraphic {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  height: fit-content;
  width: 15rem;
  margin: auto;
  padding: 0;
  border-radius: 1em;
  background-color: var(--dark-accent);
  overflow: hidden;
}

.sessionGraphic img {
  width: 100%;
  height: auto;
  border: 0.25rem solid aliceblue;
  border-radius: 1rem;
}

.sessionGraphic .experiment,
.sessionGraphic .drama,
.sessionGraphic .awareness {
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center; /* Center icon vertically */
  width: 100%;
  height: 12rem; /* Adjust height as needed */
  padding: 1rem;
}.sessionGraphic {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  height: fit-content;
  width: 15rem;
  margin: auto;
  padding: 0;
  border-radius: 1em;
  background-color: var(--dark-accent);
  overflow: hidden;
}

.sessionGraphic img {
  width: 100%;
  height: auto;
  border: 0.25rem solid aliceblue;
  border-radius: 1rem;
}

.sessionGraphic .experiment,
.sessionGraphic .drama,
.sessionGraphic .awareness {
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center; /* Center icon vertically */
  width: 100%;
  height: 12rem; /* Adjust height as needed */
  padding: 1rem;
}

.sessionGraphic svg {
  max-width: 100%;
  max-height: 100%; /* Ensure the SVG does not exceed its container */
}

.materialTitle {
  width: fit-content;
  max-width: 12rem;
  background-color: var(--primary-green);
  border-radius: 1rem;
  border: 0.25rem solid aliceblue;
  margin: 0.25rem auto;
  padding: 0.5rem 1rem;
}



.changeSession {
  height: 3.5rem;
  width: 3.5rem;
  position: absolute;
  padding: 0.25rem;
  border-radius: 100%;
}

.changeSession.forward {
  right: 0;
}

.changeSession.back {
  left: 0;
}

