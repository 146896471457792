.aboutGWE {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 1rem;
  padding: 1rem;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 1rem 0;

    h2,
    h3 {
      font-weight: bold;
    }

    p {
      text-align: center;
    }
  }
}
