.guide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .guideSections{
        height: fit-content;
        padding: 1rem;
        background-color: rgba(135, 206, 235, 1);
        border-radius: 1rem;
      margin-bottom: 1rem;

        button{
            //width: 100%;
        }

      .pageTitle{
        margin: 0 0 1rem 0;
      }
    }

  section {
    // Spacing
    //padding: 1rem;

    h1 {
      text-align: center;
    }

    p {
      margin-bottom: 1rem;
      text-align: left;
    }

    li {
      margin-bottom: 1rem;
      text-align: left;
        list-style-type: disc;
        list-style-position: inside;
    }
  }
}

.textLink {
    color: aliceblue;
    font-weight: bold;
    background-color: var(--primary-green);
    padding: 0.25rem;
    border-radius: 1rem;
}
.sectionButton{
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    width: 15rem;
}

@media only screen and (min-width: 768px){
  .guideSections{
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: auto;
  }
}