/* FONTS */
h1,
h2,
h3,
p,
button,
table,
label {
  font-family: "Quicksand", sans-serif;
}
html {
  font-size: 100%;
  line-height: 1.5em;
}
h1 {
  font-size: 1.5em;
  font-weight: 600;
}
h2 {
  font-size: 1.3em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1.1em;
}
p,
input,
label {
  font-size: 1em;
  line-height: 1.5em;
}

strong {
  font-weight: bold;
}
i {
  font-style: italic;
}

/* Media Queries */
@media screen and (min-width: 1400px) {
  html {
    font-size: 110%;
  } /*20px*/
}

@media screen and (min-width: 2500px) {
  html {
    font-size: 200%;
  } /*20px*/
}

@media screen and (min-width: 3500px) {
  html {
    font-size: 300%;
  } /*20px*/
}

@media screen and (min-width: 4500px) {
  html {
    font-size: 350%;
  } /*20px*/
}
