.awarenessWrap {
  overflow-anchor: none;
}
.awarenessWrap ul {
  padding-left: 1rem;
}
.awarenessWrap ul li {
  margin-bottom: 1rem;
  list-style-type: disc;
}
.awarenessWrap ul ul li {
  list-style-type: circle;
}
.awarenessWrap ul ul ul li {
  list-style-type: disc;
}
.awarenessWrap h1 {
  margin: 1rem;
}
.awarenessWrap section {
  overflow-anchor: none;
  background-color: var(--dark-accent);
  border-radius: 1rem;
  padding: 1rem;
  color: white;
  height: fit-content;
  width: fit-content;
  max-width: 100%;
}
.awarenessWrap .supplies {
  max-width: 20rem;
}
.awarenessWrap .supplies section {
  position: sticky;
  top: 1rem;
}
.awarenessWrap .supplies ul li {
  margin-bottom: 1rem;
}
.awarenessWrap .body {
  overflow-anchor: none;
}
.awarenessWrap .body section {
  margin-bottom: 1rem;
}
.awarenessWrap .body h2 {
  text-align: center;
  font-weight: bold;
}
.awarenessWrap .body iframe {
  width: 100%;
  height: 500px;
  border-radius: 1rem;
}
.awarenessWrap .body img {
  width: 100%;
  border-radius: 1rem;
}
.awarenessWrap .body .videos {
  border-top: 0.5rem solid var(--primary-blue);
  padding-top: 1rem;
  margin-top: 1rem;
}
.awarenessWrap .instructions {
  width: 100%;
  overflow-anchor: none;
}
.awarenessWrap .instructions a {
  background-color: var(--primary-green);
  padding: 0.25rem;
  border-radius: 1rem;
}
.awarenessWrap .videoTranscript {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.awarenessWrap .videoTranscript h3 {
  font-weight: bold;
}
.awarenessWrap .videoTranscript p {
  margin-bottom: 1rem;
  width: 100%;
}
.awarenessWrap aside img {
  width: 100%;
}

