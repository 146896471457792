/* Loading Icon */
#loading {
  display: none;
  position: fixed;
  bottom: 20px; /* Adjust the bottom position as desired */
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 50%;
  justify-content: center;
  align-items: center;
  opacity: 50%;
}

.loadCircle {
  /*display: none;*/

  width: 25%;
  height: fit-content;
  min-width: 2rem;
  min-height: 2rem;

  /* background-color: hsla(0, 0%, 100%, 0); */
  padding: 0;
  margin: 0;
  border-radius: 100%;

  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.loadCircle.noBG {
  background-color: transparent;
}
#loading img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

#loading.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
#loading.loaded{
    display: none;
}

 */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
