/* APP GLOBAL */
.lightText {
  color: aliceblue;
}

.medWhiteBorder {
  border: 0.25rem solid aliceblue;
}

.roundBorder {
  border-radius: 1rem;
}

.circleBorder {
  border-radius: 100%;
}

.darkAccent {
  background-color: var(--dark-accent);
}

.primaryBlue {
  background-color: var(--primary-blue);
}

.darkShadow {
  box-shadow: 0 0 1rem dimgrey;
}

* {
  overflow-anchor: none;
}

.App {
  display: grid;
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  font-weight: 400;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--primary-blue);
}

@media only screen and (max-height: 500px) {
  /*
  .App{
    height: 60%;
    width: 80%;
    margin: 0;
  }

   */
}

