.Read {
  min-height: 100%;
  max-width: 100%;
}

.ReaderContent {
  max-width: 95vw;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.materialHeader {
  padding: 0.25rem 1rem;
  margin: 1rem 0;
  background-color: var(--dark-accent);
  border-radius: 1rem;
  color: aliceblue;
}
.materialHeader h1 {
  font-weight: normal;
}

/* Page Image & Container */
.pageImgFrame {
  box-sizing: border-box;
  height: 80vh;
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
}
.pageImgFrame .pageImg {
  box-sizing: border-box;
  width: auto;
  height: 100%;
  border: 0.5rem solid aliceblue;
  border-radius: 1rem;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  min-width: 20vw;
}

.pageImgFrame.skinnyWindow {
  width: 80vw;
  height: auto;
}
.pageImgFrame.skinnyWindow .pageImg {
  width: 100%;
  height: auto;
}

.pageImgFrame.zoom {
  width: 80vw;
  height: 80vh;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: baseline;
  overflow-y: auto;
  border: 0.5rem solid aliceblue;
  border-radius: 1rem;
}
.pageImgFrame.zoom .pageImg {
  width: 100%;
  height: auto;
  overflow-y: auto;
  border: none;
}

/* Translation */
.translation {
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Size */
  padding: 0.5rem;
  background-color: var(--dark-accent);
  color: aliceblue;
  border-radius: 1rem;
}
.translation p {
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.2rem;
  text-align: center;
}

#nextPageInfo {
  right: 1rem;
  left: auto;
}
#nextPageInfo .arrow {
  margin-right: 1rem;
  background-color: var(--light-blue);
}

#imgWrap {
  height: 100%;
  width: fit-content;
}

#imgWrap.sizeWidth {
  width: 100%;
  height: fit-content;
}

#pageImg {
    background-color: var(--light-blue);
  border: 0.25rem solid aliceblue;
  border-radius: 1rem;
  height: 100%;
  width: auto;
}

#imgWrap.sizeWidth #pageImg {
  width: 100%;
  max-height: 100%;
}

.zoomWrap {
  position: absolute;
  top: 0;
  right: 0;
}

