.ReaderStart {
  position: relative;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: var(--dark-accent);
  /*
    background: linear-gradient(
                    180deg,
                    hsl(200,70%,60%) 0%,
                    hsl(200,70%,40%) 100%
    );

     */
  text-align: center;
}
.ReaderStart .readerTitleAuthor {
  margin-bottom: 0.5rem;
}
.ReaderStart .readByName {
  margin: 0.5rem;
}
.ReaderStart .readerTitleAuthor,
.ReaderStart .readerReadBy {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 1rem;
}
.ReaderStart hr {
  border-color: rgba(0, 0, 0, 0.2);
  width: 60%;
}
.ReaderStart #playNarration {
  margin-top: 1rem;
}
.ReaderStart #readerStartLoading {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--yellow);
  color: var(--blue);
}
.ReaderStart #readerStartLoading p {
  animation: pulse 2s infinite ease-in-out;
  margin-right: 1rem;
}
.ReaderStart .readerStartLoadingWrap {
  padding-right: 1.5rem;
  position: relative;
}
.ReaderStart .startDescr {
  border-top: 0.25rem solid var(--primary-blue);
  border-bottom: 0.25rem solid var(--primary-blue);
  margin: 1rem 0;
  padding: 0.5rem 0;
}
.ReaderStart .sessionInfo {
  background-color: var(--primary-blue);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  border: 0.25rem solid aliceblue;
}
.ReaderStart .sessionInfo h1 {
  margin: 0;
}

