.aboutGWE {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
}
.aboutGWE section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}
.aboutGWE section h2,
.aboutGWE section h3 {
  font-weight: bold;
}
.aboutGWE section p {
  text-align: center;
}

