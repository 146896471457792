p {
  margin-bottom: 0;
}

.LibraryReadBy {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;

  border-radius: 1rem;
  color: white;
}
.LibraryReadBy p {
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

.readByContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.libReadByPhoto {
  width: 10em;
  height: 10em;
  margin: 1rem;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  border-radius: 100%;
  background-color: var(--primary-green);
}
.libReadByPhoto img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.2rem solid aliceblue;

  background: hsl(128, 35%, 45%);
  background: linear-gradient(
    180deg,
    hsl(128, 45%, 60%) 0%,
    hsl(128, 25%, 45%) 100%
  );
}
.libReadByName {
  text-align: center;
  background-color: transparent;
}
